import React from 'react';
import MyImage from '../assets/images/nnb.jpg';
import AppStyle from '../assets/colors/Appstyle';
import { ReactTyped as Typed } from 'react-typed';
import Spring_Boot from '../assets/images/icons8-spring-boot-480.png'
import Java from '../assets/images/icons8-java-240.png'
import Tailwind from '../assets/images/icons8-tailwindcss-480.png'
import spring_framework from '../assets/images/spring_framework.png'
import postgresql from '../assets/images/icons8-postgresql-480.png'
import mysql from '../assets/images/mysql-logo-svgrepo-com.svg'
import mongoDb from '../assets/images/mongodb-svgrepo-com.svg'
import docker from '../assets/images/docker-svgrepo-com.svg'
import postman from '../assets/images/postman-icon-svgrepo-com.svg'
import aws from '../assets/images/aws-svgrepo-com.svg'
import angular from '../assets/images/angular-svgrepo-com.svg'
import figma from '../assets/images/figma-svgrepo-com.svg'
import photoshop from '../assets/images/photoshop-svgrepo-com.svg'
import python from '../assets/images/python-svgrepo-com.svg'
import jira from '../assets/images/jira-svgrepo-com.svg'


import {
    FaLaravel,
    FaReact,
    FaVuejs,
    FaPhp,
    FaDatabase,
    FaCss3Alt,
    FaBootstrap,
    FaJsSquare,
    FaHtml5,
    FaLeaf ,
    FaJava , 
    FaSeedling 
} from 'react-icons/fa';

const Home = () => {
    const iconStyle = { color: AppStyle.white, marginRight: '10px' };
    const imageStyle = { width: '40px', height: '40px', objectFit: 'contain' };

    return (
        <div className='flex flex-col items-center'>
            <div className='flex flex-col md:flex-row justify-center  items-center mb-[10vh]'>
                <div>
                    <img
                        src={MyImage}
                        alt="Nabil El Hakimi"
                        className='rounded-xl  mb-3 h-[80vw] w-[80vw] md:h-[25vw] md:w-[25vw] shadow-black shadow-lg hover:shadow-xl
                         hover:shadow-black transition-all hover:borde ' 
                        style={{ transform: 'scaleX(-1)' }}
                        title='Nabil El Hakimi'
                    />
                </div>
                <div className='ml-0 md:ml-10 w-[80vw] md:w-[25vw] mt-5 md:mt-0 '>
                    <h1 className='text-3xl md:text-6xl font-bold text-white uppercase ' >Nabil</h1>
                    <h1 className='text-3xl md:text-6xl font-bold text-white uppercase'>El Hakimi</h1>
                    <Typed
                        strings={['Full Stack Developer',"Java | Angular" , "Java | ReactJs" , 'Laravel | ReactJs']}
                        typeSpeed={80} 
                        backSpeed={40}
                        loop
                        className={` text-xl md:text-2xl uppercase`}
                        style={{ color: AppStyle.yellow }}
                    />
                </div>
            </div>
            <div className="flex justify-center">
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 justify-items-center">

            <div className="icon-container flex items-center justify-center p-2 bg-gray-50 rounded-full shadow-md w-full">
    <img src={spring_framework} alt="Spring Framework Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Spring</span>
  </div>
  <div className="icon-container flex items-center justify-center p-2 bg-gray-50 rounded-full shadow-md w-full">
    <img src={Spring_Boot} alt="Spring Boot Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Spring Boot</span>
  </div>
 
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaLaravel size={40} className="text-red-500" />
    <span className="ml-2 text-sm font-medium">Laravel</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaReact size={40} className="text-blue-500" />
    <span className="ml-2 text-sm font-medium">React</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaVuejs size={40} className="text-green-400" />
    <span className="ml-2 text-sm font-medium">VueJs</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaBootstrap size={40} className="text-purple-500" />
    <span className="ml-2 text-sm font-medium">Bootstrap</span>
  </div>
  
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={Java} alt="Java Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Java</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={python} alt="Python Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Python</span>
  </div>

  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaPhp size={40} className="text-indigo-600" />
    <span className="ml-2 text-sm font-medium">PHP</span>
  </div>

  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={postgresql} alt="PostgreSQL Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">PostgreSQL</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={mongoDb} alt="MongoDB Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">MongoDB</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={mysql} alt="MySQL Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">MySQL</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={aws} alt="AWS Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">AWS</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={docker} alt="Docker Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Docker</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={postman} alt="Postman Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Postman</span>
  </div>
  
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={photoshop} alt="Photoshop Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Photoshop</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={figma} alt="Figma Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Figma</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={jira} alt="Figma Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Jira</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <img src={Tailwind} alt="Tailwind CSS Icon" className="w-12 h-12" />
    <span className="ml-2 text-sm font-medium">Tailwind</span>
  </div>
  
  {/* <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaDatabase size={40} className="text-gray-500" />
    <span className="ml-2 text-sm font-medium">Database</span>
  </div> */}
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaJsSquare size={40} className="text-yellow-400" />
    <span className="ml-2 text-sm font-medium">JavaScript</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaHtml5 size={40} className="text-orange-500" />
    <span className="ml-2 text-sm font-medium">HTML5</span>
  </div>
  <div className="icon-container flex items-center p-2 justify-center bg-gray-50 rounded-full shadow-md w-full">
    <FaCss3Alt size={40} className="text-blue-600" />
    <span className="ml-2 text-sm font-medium">CSS3</span>
  </div>
</div>

</div>

        </div>
    );
}

export default Home;
